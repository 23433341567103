<template>
  <div class="common-center" style="height: 100%;">首页</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>



</style>